import React from 'react';

import { localeToLang } from 'providers/prismic/utils/localeToLang';
import { getLayout, IPrismicLayout } from 'providers/prismic/layout';
import { getHome, IPrismicHome } from 'providers/prismic/home';
import { getAllArticles, IArticle } from 'providers/prismic/articles';
import { Layout } from 'containers/layout/Layout';
import { Slices } from 'containers/slices/Slices';
import { Header } from 'components/header/Header';
import { EpicHero } from 'components/hero/EpicHero';
import { Ticker } from 'components/ticker/Ticker';
import { TickerItem } from 'components/ticker/TickerItem';
import { config } from 'utils/config';

type Props = {
  preview?: boolean;
  layout: IPrismicLayout;
  home: IPrismicHome;
  latestArticles: IArticle[];
};

export default function HomePage({ preview, layout, home, latestArticles }: Props) {
  return (
    <Layout layout={layout} header={<Header color="brand" />} hasFooter isPadded preview={preview}>
      <EpicHero
        tagline={home.tagline}
        title={home.title}
        text={home.description}
        buttonLabel={home.buttonLabel}
        buttonLink={home.buttonLink}
        image={home.image}
        video={home.video}
      >
        {home.showTicker && home.tickerLink && (
          <Ticker title={home.tickerLabel} hideTitle={!home.tickerLabel}>
            <TickerItem
              headline={home.tickerTitle}
              image={home.tickerImage}
              link={home.tickerLink}
            />
          </Ticker>
        )}
        {home.showTicker && !home.tickerLink && latestArticles?.length > 0 && (
          <Ticker title={home.tickerLabel}>
            {latestArticles?.map((article) => (
              <TickerItem
                key={article.link.href}
                headline={article.title}
                image={article.image}
                link={article.link}
              />
            ))}
          </Ticker>
        )}
      </EpicHero>
      <Slices slices={home.slices} />
    </Layout>
  );
}

export async function getStaticProps({ preview = false, previewData, locale }) {
  const lang = localeToLang(locale);
  const layout = await getLayout({ previewData, lang });
  const home = await getHome({ previewData, lang, redirects: layout?.redirects });

  const getlatestArticleItems = async () => {
    const data = await getAllArticles({
      previewData,
      lang,
      limit: 2,
      redirects: layout?.redirects,
    });
    return data?.items ?? null;
  };

  const latestArticles = !home?.tickerLink ? await getlatestArticleItems() : null;

  return {
    notFound: !home,
    props: {
      preview,
      layout,
      home,
      latestArticles,
    },
    revalidate: config.revalidate,
  };
}
